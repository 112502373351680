<template>
  <div class="pagination" v-if="total">
    <span class="custom">共 {{total}} 条</span>
    <!-- 上一页 -->
    <a href="javascript:;" :class="{ disabled: myCurrentPage <= 1 }" @click="changePage(-1, true)">
      &lt;
    </a>
    <!-- 页码 -->
    <a href="javascript:;" v-for="item in pager.pageButtonRows" :key="item" :class="{ active: item === myCurrentPage }" @click="changePage(item)">
      <span>{{item}}</span>
    </a>
    <!-- 下一页 -->
    <a href="javascript:;" :class="{ disabled: myCurrentPage >= pager.pageCount }" @click="changePage(+1, true)">
      &gt;
    </a>
    <span class="custom">
      前往
      <input type="text" v-model="inputCurrentPage" @keyup.enter="changePage(inputCurrentPage - 0)">
      页
    </span>
  </div>
</template>

<script>
import { ref, watch, computed } from 'vue'

export default {
  name: 'pagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 10
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  emits: ['current-change'],
  setup(props, { emit }) {
    const pageButtonCount = 5
    const myCurrentPage = ref(1)
    const inputCurrentPage = ref(1)
    const total = ref(100)
    const pageSize = ref(10)

    const pager = computed(() => {
      const pageCount = Math.ceil(total.value / pageSize.value)
      const offset = Math.floor(pageButtonCount / 2)
      let startPage = myCurrentPage.value - offset
      let endPage = (startPage + pageButtonCount) - 1

      // 处理不合法行为
      if(startPage < 1) {
        startPage = 1
        endPage = (startPage + pageButtonCount) - 1 > pageCount ? pageCount : (startPage + pageButtonCount) - 1
      }
      if(endPage > pageCount) {
        endPage = pageCount
        startPage = (endPage - pageButtonCount) + 1 < 1 ? 1 : (endPage - pageButtonCount) + 1
      }

      // 得到页码按钮数据
      const pageButtonRows = []
      for(let i = startPage; i <= endPage; i++) {
        pageButtonRows.push(i)
      }

      return {
        pageCount,
        startPage,
        endPage,
        pageButtonRows
      }
    })

    // 监听 props 的变化
    watch(props, () => {
      total.value = props.total
      myCurrentPage.value = props.currentPage
      inputCurrentPage.value = props.currentPage
      pageSize.value = props.pageSize
    }, {
      immediate: true
    })

    // 切换页码
    const changePage = (newPage, oneStep) => {
      myCurrentPage.value = oneStep ? myCurrentPage.value + newPage : newPage

      // 边界处理
      if(myCurrentPage.value < 1) {
        myCurrentPage.value = 1
      }
      if(myCurrentPage.value > pager.value.pageCount) {
        myCurrentPage.value = pager.value.pageCount
      }

      inputCurrentPage.value = myCurrentPage.value
      if(myCurrentPage.value > 1 || myCurrentPage.value < pager.value.pageCount) {
        emit('current-change', myCurrentPage.value)
      }
    }

    return {
      myCurrentPage,
      inputCurrentPage,
      pager,
      changePage
    }
  }
}
</script>

<style lang="less" scoped>
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 0;
  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 38px;
    height: 36px;
    background-color: #F4F4F5;
    border-radius: 4px;
    margin-right: 10px;
    &.disabled {
      cursor: not-allowed;
      color: #C2C6CF;
    }
    &.active {
      background-color: #e93323;
      color: white;
    }
  }
  .custom {
    color: #555;
    margin: 0 15px;
    input {
      width: 44px;
      height: 32px;
      text-align: center;
      border: 1px solid #dcdfe6;
      border-radius: 3px;
      margin: 0 5px;
      transition: border-color .2s;
      cursor: pointer;
      &:focus {
        border-color: #e93323;
      }
    }
  }
}
</style>
