<template>
  <div class="order">
    <div class="item" v-for="(item, index) in list" :key="item.order_sn">
      <div class="goods">
        <div class="acea-row row-between">
          <div class="date">订单编号：{{item.order_sn}}</div>
          <div class="status">{{filterStatus(item.status)}}</div>
        </div>
        <div class="acea-row">
          <div class="picture">
            <img :src="item.attr.absolute_pic" alt="">
          </div>
          <div class="desc">
            <div class="title">{{item.attr.goods_name}}</div>
            <div class="acea-row row-between-wrapper">
              <div class="price">￥{{item.attr.price}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="acea-row row-between-wrapper info">
        <div class="left">
          <p>订单日期：{{item.create_time_text}}</p>
          <p v-if="item.status === 0">
            剩余支付时间：<count-down :time="item.createtime" @finish="countDownFinish(index)"></count-down>
          </p>
        </div>
        <div class="right">
          <p>共{{item.cart_num}}件商品，总金额 <span>￥{{item.total_fee}}</span></p>
          <div class="btn-group">
            <button type="button" class="btn" @click="cancelOrder(index)" v-if="item.status === 0">取消订单</button>
            <button type="button" class="btn btn-pay" @click="$router.push(`/payment/${item.pay_order_sn}`)" v-if="item.status === 0">立即支付</button>
            <button type="button" class="btn btn-confirm" @click="confirmOrder(index)" v-if="item.status === 2">确认订单</button>
            <button type="button" class="btn" @click="$router.push({name: 'orderDetail', query: {id: item.id}})">查看详情</button>
          </div>
        </div>
      </div>
    </div>
    <pagination :total="total" :page-size="queryData.limit" @current-change="currentChange" />
  </div>
</template>

<script>
import Pagination from '@/components/pagination'
import CountDown from '@/components/count-down'
import MessageBox from '@/components/message-box/message'
import { ref, reactive } from 'vue'
import { getOrderList, cancelOder, confirm } from '@/api/order'

export default {
  name: 'orderPage',
  setup() {
    // 获取订单列表
    const queryData = reactive({
      page: 1,
      limit: 5
    })
    const list = ref([])
    const total = ref(0)
    const getList = async () => {
      const { data } = await getOrderList(queryData)
      list.value = data.data
      total.value = data.total
    }
    getList()

    // 分页
    const currentChange = page => {
      queryData.page = page
      getList()
    }

    // 取消订单
    const cancelOrder = index => {
      MessageBox.confirm('确定要取消该订单吗？', '温馨提示', {
        showWarnIcon: true
      }).then(async () => {
        await cancelOder({ order_sn: list.value[index].order_sn });
        list.value[index].status = 6;
      }).catch(() => {})
    }

    // 确认订单
    const confirmOrder = (index) => {
      MessageBox.confirm('确定要完成该订单吗？', '温馨提示', {
        showWarnIcon: true
      }).then(async () => {
        confirm({
          id: list.value[index].id
        }).then((res) => {
          list.value[index].status = 3;
        });
      }).catch(() => {})
    }

    // 支付剩余时间结束
    const countDownFinish = (index) => {
      list.value[index].status = 6;
    }

    // 状态过滤器
    const filterStatus = status => {
      if(status === 0) {
        return '未支付'
      } else if(status === 1) {
        return '待发货'
      } else if(status === 2) {
        return '待确认'
      } else if(status === 3) {
        return '已完成'
      } else if(status === 4) {
        return '退款中'
      } else if(status === 5) {
        return '已退款'
      } else if(status === 6) {
        return '已取消'
      }
    }

    return {
      queryData,
      list,
      total,
      currentChange,
      cancelOrder,
      confirmOrder,
      countDownFinish,
      filterStatus
    }
  },
  components: {
    [Pagination.name]: Pagination,
    [CountDown.name]: CountDown
  }
}
</script>

<style lang="less" scoped>
.order {
  background-color: white;
  padding: 0 50px;
  .item {
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: 0;
    }
    .goods {
      padding: 20px 0;
      border-bottom: 1px dashed #ddd;
      font-size: 14px;
      > div:first-child {
        margin-bottom: 12px;
      }
      .status {
        color: #e93323;
      }
      .picture {
        width: 100px;
        height: 100px;
        margin-right: 20px;
      }
      .desc {
        width: 500px;
        .title {
          margin-bottom: 10px;
        }
        .price {
          color: red;
        }
        .number {
          color: #aaa;
        }
      }
    }
    .info {
      padding: 20px 0;
      .right {
        text-align: right;
      }
      p {
        color: #666;
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
        span {
          color: red;
        }
      }
      .btn {
        width: 110px;
        height: 40px;
        border: 1px solid #999;
        border-radius: 4px;
        font-size: 14px;
        color: #666;
        cursor: pointer;
        background-color: transparent;
        margin-left: 10px;
        &-pay {
          background-color: #e93323;
          border-color: #e93323;
          color: white;
        }
      }
    }
  }
}
</style>
