import { render, createVNode } from 'vue'
import MessageBox from './index'

const div = document.createElement('div')
div.setAttribute('class', 'message-box_container')
document.body.appendChild(div)

const MessageBoxFn = options => {
  return new Promise((resolve, reject) => {
    const confirm = () => {
      render(null, div)
      resolve()
    }
    const cancel = () => {
      render(null, div)
      reject('cancel')
    }

    const vnode = createVNode(MessageBox, {
      ...options,
      confirm,
      cancel
    })
    render(vnode, div)
  })
}

MessageBoxFn.confirm = (message, title, options = {}) => {
  return MessageBoxFn({
    showCancelButton: true,
    ...options,
    message,
    title
  })
}

export default MessageBoxFn
