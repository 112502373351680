<template>
  <transition name="fade">
    <div class="message-mask" @click="cancel" v-if="visible"></div>
  </transition>
  <transition name="fade-down">
    <div class="message-box" v-if="visible">
      <div class="header">
        <div class="title">{{title}}</div>
        <a href="javascript:;" class="icon" @click="cancel">×</a>
      </div>
      <div class="acea-row row-middle body">
        <strong class="icon" v-if="showWarnIcon">！</strong>
        <span>{{message}}</span>
      </div>
      <div class="footer">
        <button type="button" class="btn" @click="cancel" v-if="showCancelButton">{{cancelButtonText}}</button>
        <button type="button" class="btn btn-confirm" @click="confirm">{{confirmButtonText}}</button>
      </div>
    </div>
  </transition>
</template>

<script>
import { ref, onMounted } from 'vue'

export default {
  name: 'message-box',
  props: {
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    showCancelButton: {
      type: Boolean,
      default: false
    },
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    showWarnIcon: {
      type: Boolean,
      default: false
    },
    confirm: {
      type: Function
    },
    cancel: {
      type: Function
    }
  },
  setup() {
    const visible = ref(false)
    onMounted(() => {
      visible.value = true
    })

    return {
      visible
    }
  }
}
</script>

<style lang="less" scoped>
.message-mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .6);
}

.message-box {
  position: fixed;
  top: 40%;
  left: 50%;
  margin-left: -210px;
  width: 420px;
  padding: 10px 15px;
  border-radius: 4px;
  background-color: white;
  z-index: 9999;
  .header {
    position: relative;
    .title {
      font-size: 18px;
    }
    .icon {
      position: absolute;
      right: 0;
      top: 40%;
      transform: translateY(-50%);
      font-size: 20px;
      color: #999;
    }
  }
  .body {
    padding: 20px 0;
    font-size: 16px;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #E6A23C;
      color: white;
      margin-right: 10px;
    }
  }
  .footer {
    text-align: right;
    .btn {
      padding: 9px 15px;
      border: 1px solid #ddd;
      border-radius: 4px;
      font-size: 14px;
      margin-left: 15px;
      color: #666;
      background-color: transparent;
      cursor: pointer;
      &.btn-confirm {
        color: white;
        border-color: #409EFF;
        background-color: #409EFF;
      }
    }
  }
}
</style>
